import axios from "axios";
import React, { Component } from "react";
import DataTable from 'react-data-table-component';
import Checkbox from "@mui/material/Checkbox";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import EmailPopUp from "./EmailPopUp";


const dateSort = (rowA, rowB) => {
    const a = new Date(rowA.dateAdded);
    const b = new Date(rowB.dateAdded);

    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
};

const columns = [
    {
        name: 'Name',
        selector: row => row.contactName.charAt(0).toUpperCase() + row.contactName.slice(1),
        sortable: true,
    },
    {
        name: 'Phone',
        selector: row => row.phone,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Date Created',
        selector: row => new Date(row.dateAdded).toDateString(),
        sortable: true,
        sortFunction: dateSort
    },
    {
        name: 'Tags',
        selector: row => (row.tags).join(", "),
    },
];

const paginationComponentOptions = {
    rowsPerPageText: 'Rows Per Page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
};
const isIndeterminate = (indeterminate) => indeterminate;
const selectableRowsComponentProps = { indeterminate: isIndeterminate };

export default class Contact extends Component {
    state = {
        contacts: [],
        nextPageLink: '',
        pending: false,
        total_contacts: 0,
        sendEmail: [],
        ExceptionList: ['ramkumar@socialhi5.com', 'kuraray.dental62@gmail.com', 'shankar.kuppusw@gmail.com', 'shankar@socialhi5.com', 'jagan@adshi5.com', 'gokulselvan@adshi5.com', 'revathi@adshi5.com'],
        userEmail: 'kuraray.dental62@gmail.com',
        // userEmail: window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("email").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"), // eslint-disable-line
        locationId: window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("location").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"), // eslint-disable-line
        userName: '',
        userId: '',
        apiKey: '',
        userType: '',
        open: false
    }
    async componentDidMount() {
        await axios.get(`https://rest.gohighlevel.com/v1/locations/${this.state.locationId}`, {
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55X2lkIjoiU202N2E2QTI2RHlLbWZVS1NtMngiLCJ2ZXJzaW9uIjoxLCJpYXQiOjE2MzUxNTc1NTQyNDUsInN1YiI6ImdkeFpiaEgyUnA0T0hGWHkxcmZBIn0.X04JbvErsZRYBlKO5LHVibitPrO-PdDz7cB73i2D66Q'
            }
        }).then(locationData => {
            this.setState({
                apiKey: locationData.data.apiKey
            })
        })
        await axios.get('https://rest.gohighlevel.com/v1/users/', {
            headers: {
                'Authorization': `Bearer ${this.state.apiKey}`
            }
        }).then(users => {
            users.data.users.forEach(user => {
                // console.log(user)
                if (user.email.trim().toLowerCase() === this.state.userEmail.trim().toLowerCase()) {
                    // console.log(user.roles.role)
                    this.setState({
                        userId: user.id,
                        userType: user.roles.role,
                        userName: user.name
                    })
                }
            });
        })
        await axios.get('https://rest.gohighlevel.com/v1/contacts/?limit=100', {
            headers: {
                'Authorization': `Bearer ${this.state.apiKey}`
            }
        }).then(values => {
            values.data.contacts.forEach(contact => {
                if (this.state.userType == 'admin' || this.state.ExceptionList.includes(this.state.userEmail.toLocaleLowerCase())) {
                    this.setState({
                        contacts: [...this.state.contacts, contact],
                    })
                } else {
                    if (contact.assignedTo != null) {
                        if (contact.assignedTo.trim().toLowerCase() === this.state.userId.trim().toLowerCase()) {
                            this.setState({
                                contacts: [...this.state.contacts, contact],
                            })
                        }
                    }
                }
            })
            this.setState({
                total_contacts: values.data.meta.total,
                nextPageUrl: values.data.meta.nextPageUrl
            })
        })
        this.setState({
            pending: false
        })
    }

    selectedContacts = (e) => {
        this.setState({
            sendEmail: e.selectedRows
        })
        if (e.selectedCount) {
            document.getElementById('sendEmail').style.background = '#54ab1a';
        } else {
            document.getElementById('sendEmail').style.background = '#5ebeeb'
        }
    }
    sendEmail = (e) => {
        // console.log(this.state.sendEmail.length)
        if (this.state.sendEmail.length === 0) {
            alert("Please select one or more contats to send Email.")
        } else {
            this.setState({ open: true });
        }
    }

    onCloseModal = () => {
        this.setState({ open: false });
    };
    handleSelectAll = selectAll => () => {
        selectAll();
    };
    render() {
        const { open } = this.state;
        console.log("test", this.props);

        return (
            <>

                <Modal open={open} onClose={this.onCloseModal}>
                    <EmailPopUp contacts={this.state.sendEmail} userId={this.state.userId} locationId={this.state.locationId} userId={this.state.userId} from_name={this.state.userName} from_email={this.state.userEmail} />
                </Modal>
                <div className="invoice-group invoice-lists">
                    <div className="container" style={{ marginTop: '20px' }}>
                        <div className="sendEmail" style={{ textAlign: 'right', margin: '13px 0' }}>
                            <button style={{}} id="sendEmail" onClick={this.sendEmail}>
                                Send Email
                            </button>
                        </div>
                        <DataTable
                            title="All Contacts"
                            columns={columns}
                            data={this.state.contacts}
                            pagination
                            paginationComponentOptions={paginationComponentOptions}
                            selectableRows
                            selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={selectableRowsComponentProps}
                            onSelectedRowsChange={this.selectedContacts}
                            contextActions={this.contextActions}
                            paginationTotalRows={this.state.contacts.length}
                        // handleSelectAll = {this.selectAll}
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent}
                        />
                    </div>
                </div>
            </>
        )
    }
}

