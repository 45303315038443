import "./App.css";
import { Switch, Route } from "react-router-dom";
//import StepForm from './Components/Form/StepForm';
//import Email from './Components/Form/Email';
//import Invoice from './Invoice/Invoices'
//import Unsubscribe from './Unsubscribe'
//import Onboarding from './Components/Onboarding/Onboarding';
//import Index from './HomePage/home';
//import Affiliate from './affiliate';
// import InvoiceList from './Invoice/List';
//import NotFound from './notfound';
//import Blog from './blog';
//import AffiliateForm from './AffiliateForm'
//import Privacy from './Privacy';
//import Lpnewthank from './thank-di';
//import LandingPage1 from './Landing Page 1/landingpage1'
import React, { lazy } from "react";
import { Suspense } from "react";

import CallsReportRestaurant from "./Restaurant/CallsReport";
// import SpeechToText from './AgiLeads/DataCollect/SpeechToText';
import Contact from "./Kuraray/Contacts";
import Settings from "./Kuraray/Settings";
import KurarayUpload from "./Kuraray/Lp2";
const RestaurantServices = lazy(() =>
  import("./RestaurantsLP/RestaurantServices")
);
const RestaurantsLp = lazy(() => import("./RestaurantsLP/RestaurantsLp"));
const RestPrivacy = lazy(() => import("./RestaurantsLP/restprivacy"));
const RestaurantsLpThank = lazy(() =>
  import("./RestaurantsLP/RestaurantsLpThank")
);
const LandingPage1 = lazy(() => import("./Landing Page 1/landingpage1"));
const Lpnewthank = lazy(() => import("./thank-di"));
const Privacy = lazy(() => import("./Privacy"));
const AffiliateForm = lazy(() => import("./AffiliateForm"));
const Blog = lazy(() => import("./blog"));
const NotFound = lazy(() => import("./notfound"));
const InvoiceList = lazy(() => import("./Invoice/List"));
const Affiliate = lazy(() => import("./affiliate"));
const Index = lazy(() => import("./HomePage/home"));
const IndexDentistry = lazy(() => import("./HomePage/homedentistry"));
const Onboarding = lazy(() => import("./Components/Onboarding/Onboarding"));
const Invoice = lazy(() => import("./Invoice/Invoices"));
const Email = lazy(() => import("./Components/Form/Email"));
const Unsubscribe = lazy(() => import("./Unsubscribe"));
const StepForm = lazy(() => import("./Components/Form/StepForm"));
const StepFormRes = lazy(() => import("./Components/Form/StepFormRes"));
const Users = lazy(() => import("./AgiLeads/Users/Users"));
const SpamScore = lazy(() => import("./AgiLeads/SpamScore"));
const Event = lazy(() => import("./AgiLeads/Events/events"));
const Webinar = lazy(() => import("./AgiLeads/Events/webinars"));
const Upload = lazy(() => import("./AgiLeads/Upload/Upload"));
const UseLose = lazy(() => import("./Landing Page 1/uselose"));
const UseLosethank = lazy(() => import("./Landing Page 1/thanksuse"));
const Google = lazy(() => import("./Audience/Google"));
const Facebook = lazy(() => import("./Audience/Facebook"));
const Postdetails = lazy(() => import("./posts"));
const Blog2 = lazy(() => import("./whatweblog"));
const Blog3 = lazy(() => import("./RestaurantBlog"));
const Blog4 = lazy(() => import("./HowToImproveBlog"));
const CommissionFreeOrderSystem = lazy(() =>
  import("./RestaurantsLP/CommissionFreeOrderSystem")
);
const CommissionFreeOrderSystemThankYou = lazy(() =>
  import("./RestaurantsLP/CommissionFreeOrderSystemThankYou")
);

function App() {
  return (
    <>
      <Suspense fallback={<h1></h1>}>
        <Switch>
          <Route path="/" exact>
            <Index />
          </Route>
          <Route path="/dentistry" exact>
            <IndexDentistry />
          </Route>
          <Route path="/restaurant-report" exact>
            {" "}
            <CallsReportRestaurant />
          </Route>
          <Route path="/blog/restaurant-marketing" exact>
            <Blog3 />
          </Route>
          <Route path="/blog/how-to-improve-restaurant-marketing" exact>
            <Blog4 />
          </Route>
          <Route path="/blog" exact>
            <Blog />
          </Route>
          <Route path="/blog/what-is-agileads" exact>
            <Postdetails />
          </Route>
          <Route path="/blog/what-are-we" exact>
            <Blog2 />
          </Route>
          <Route path="/signup" exact>
            <StepForm />
          </Route>
          {/* <Route path="/restaurant-signup" exact>
            <StepFormRes />
          </Route> */}
          <Route path="/verify-email" exact>
            <Email />
          </Route>
          <Route path="/use-it-or-lose-it-action-based-marketing" exact>
            <UseLose />
          </Route>
          <Route
            path="/use-it-or-lose-it-action-based-marketing/thankyou"
            exact
          >
            <UseLosethank />
          </Route>
          <Route path="/implant-marketing" exact>
            <LandingPage1 />
          </Route>
          <Route path="/implant-marketing/thankyou" exact>
            <Lpnewthank />
          </Route>
          <Route path="/invoice" exact>
            <InvoiceList />
          </Route>
          <Route path="/agileads-settings" exact>
            <Settings />
          </Route>
          <Route path="/invoices/invoice-detail" exact>
            <Invoice />
          </Route>
          <Route path="/unsubscribe" exact>
            <Unsubscribe />
          </Route>
          <Route path="/onboarding" exact>
            <Onboarding />
          </Route>
          <Route path="/spam-score" exact>
            <SpamScore />
          </Route>
          <Route path="/become-an-affiliate" exact>
            <Affiliate />
          </Route>
          <Route path="/kuraray" exact>
            <Contact />
          </Route>
          <Route path="/become-an-affiliate/agileads-affiliate" exact>
            <AffiliateForm />
          </Route>
          <Route path="/facebook-audience" exact>
            <Facebook />
          </Route>
          <Route path="/google-audience" exact>
            <Google />
          </Route>
          <Route path="/privacy-policy" exact>
            <Privacy />
          </Route>
          <Route path="/users" exact>
            <Users />
          </Route>
          <Route path="/upload-contact" exact>
            <Upload />
          </Route>
          <Route path="/kuraray-upload" exact>
            <KurarayUpload />
          </Route>
          <Route path="/event" exact>
            <Event />
          </Route>
          <Route path="/webinar" exact>
            <Webinar />
          </Route>
          <Route path="/restaurant" exact>
            <RestaurantsLp />
          </Route>
          <Route path="/restaurant/privacy-policy" exact>
            <RestPrivacy />
          </Route>
          <Route path="/restaurant/thankyou" exact>
            <RestaurantsLpThank />
          </Route>
          <Route path="/restaurant/commission-free-order-system" exact>
            <CommissionFreeOrderSystem />
          </Route>
          <Route path="/restaurant/commission-free-order-system/thankyou" exact>
            <CommissionFreeOrderSystemThankYou />
          </Route>
          <Route path="/restaurant/services" exact>
            <RestaurantServices />
          </Route>
          {/* <Route path="/contact-category" exact>
            <SpeechToText />
          </Route> */}
          <Route path="*" component={NotFound} status={404}>
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
