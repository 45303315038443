import React, { Component } from 'react'
import { Navbar, Nav, Dropdown } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import EmailSettings from './EmailSettings';
import Test from './Test';
import './Style.css'
export default class Settings extends Component {
    state = {
        type: 'Email'
    }
    handleComponent = (e) => {
        this.setState({
            type : e.target.name
        })
    }
    render() {
        console.log()
        var element ;
        if (this.state.type == 'Email') {
            element = <EmailSettings/>
        } else {
            element = <Test/>
        }
        return (
            <>
                <div className="settings-navbar container">
                    <Navbar>
                        <Nav>
                            <Nav.Item onClick={this.handleComponent} name="Email">SMTP</Nav.Item>
                            {/* <Nav.Item onClick={this.handleComponent} name="Testing">Testing</Nav.Item> */}
                        </Nav>
                    </Navbar>
                    <div>
                        {element}
                    </div>
                </div>
            </>
        )
    }
}