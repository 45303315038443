import React, { Component } from "react";
import { dbKuraray } from "./.././firebase";
import Select from 'react-select'
import './Style.css'
import axios from "axios";
import SpamCheck from './SpamCheck'
import { Modal } from "react-responsive-modal";
import ReactHtmlParser from 'react-html-parser'; 

class EmailPopUp extends Component {
  state = {
    active: 1,
    template_details: {},
    selectedTemplate: '',
    options: [
    ],
    contacts: [],
    subject: '',
    open: false,
    spamResult : {}
  }
  async componentDidMount() {
    dbKuraray.collection('email_templates')
      .doc(this.props.locationId)
      .collection('users')
      // .doc(this.props.userId)
      .doc('HMi0VEKJzgqCTCrGhZIT')
      .get()
      .then(res => {

        this.setState({
          template_details : res.data()
        })
        Object.keys(res.data()).forEach(key => {
          this.setState({
            options : [...this.state.options, {label: key}]
          })
        })
      })
    this.props.contacts.forEach(contact => {
      var c = {}
      c[contact.id] = contact.email
         this.state.contacts.push(c)
    });

  }
  handleChange = (e) => {
    this.setState({
      selectedTemplate : this.state.template_details[e.label]['template']
    })
   // console.log("selet", this.state.template_details[e.label]['template'])
  }
  

  handleNext = (e) =>{
    var template = document.getElementById('emailTemplate').getElementsByTagName( 'div' )[0].innerHTML
    // var template = document.querySelector('#idIframe').contentDocument.documentElement.outerHTML
    // axios.post('https://back.agileads.co/send-email-gohighlevel', {template: JSON.stringify(template), to_users: this.state.contacts, from_name : this.props.from_name, from_email : this.props.from_email, subject: this.state.subject, userId: this.props.userId}).then(res=>{
      // axios.post('https://back.agileads.co/send-email-gmail', {template: template, to_users: this.state.contacts, from_name : this.props.from_name, from_email : this.props.from_email, subject: this.state.subject, userId: this.props.userId}).then(res=>{
      axios.post('https://back.agileads.co/send-email-gohighlevel', {template: template, to_users: this.state.contacts, from_name : this.props.from_name, from_email : this.props.from_email, subject: this.state.subject, userId: this.props.userId}).then(res=>{
      if(res.data){
      window.location.reload()
      }
      else {
        alert("Email Limit Exceed...")
      }
    })

  }

  handleSpam = (e) =>{
    var template = document.getElementById('emailTemplate').getElementsByTagName( 'div' )[0].innerHTML
      axios.post('https://agileadspython.herokuapp.com/spam-check', {email: template, from_address : this.props.from_email, subject: this.state.subject}).then(res=>{
      if(res.data){
        console.log()
      this.setState({ open:true, spamResult: res.data.spam_result });

      }
      else {

      }
    })

  }

  handleLoad = (e) => {
    var iFrameID = document.getElementById('idIframe');
    if(iFrameID) {
          iFrameID.height = "";
          iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
    } 
  }
  handleInputChange = (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })

  }
  onCloseModal = () => {
    this.setState({ open: false });
};
  render() {
   // var htmlString = this.state.template_details[this.state.e.label]['template'];
  // console.log("seleting", this.state.selectedTemplate)
    return (
      <>
      <div className="modelspam">
      <Modal className="spamchecker" open={this.state.open} onClose={this.onCloseModal}>
                    <SpamCheck spamResult={this.state.spamResult} />
                </Modal>
              </div>
        <div className="email-template">
          <div className="choose-temlate">
            <p className="select-template" >Templates and Preview</p>
            <input className="form-group subject" name="subject" placeholder="Add Subject" onChange={this.handleInputChange}/>
            <Select options={this.state.options} onChange={this.handleChange} />
          </div>

          {
            this.state.selectedTemplate ?
              <div className="email-previewer" id="email-template">
    {/* <iframe id="idIframe" src={this.state.selectedTemplate} onLoad={this.handleLoad} frameborder="0" style={{overflow:'hidden',width:'100%'}}  width="100%"></iframe> */}
    <div id="emailTemplate">
   {/* <div dangerouslySetInnerHTML={{__html: this.state.selectedTemplate }}></div> */}
   <div className="preview-content"> { ReactHtmlParser (this.state.selectedTemplate) } </div>
   
    </div>
              <div className="sendEmail" style={{ textAlign: 'right', margin: '8px 18px',marginBottom:'10px' }}>
              <button style={{marginBottom:'20px !important', margin:'0 15px'}} className="sendEmail" onClick={this.handleSpam}>
                  Check Spam
              </button>
              <button style={{marginBottom:'20px !important'}} className="sendEmail" onClick={this.handleNext}>
                  Send Email
              </button>
              
              </div>
              </div>
              : <div className="empty-template"><h3>Select Template for Preview</h3></div>
          }
        </div>


      </>

    );
  }
}
export default EmailPopUp;

