import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogTitle, IconButton } from '@material-ui/core';
import './../HomePage/css/custom.css';
import Style from './../Landing-Page/css/lp-style.css'
import './../AgiLeads/Users/Users.css'
import { dbKuraray } from "./.././firebase";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class Adduser extends Component {
    state = {
        open: false,
        name: '',
        email: '',
        password: '',
        locationId : window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("location").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")
    }
    async componentDidMount() {

    }
    

    AddEmployeeOpen = (e) => {
        e.preventDefault()
        this.setState({
            open: true
        })
    };

    AddEmployeeClose = (e) => {
        e.preventDefault()
        this.setState({
            open: false
        })
    };
   
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleRole = (e) => {
        console.log(e.target.value)
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    
    handleClick = (e) => {
        e.preventDefault()
        dbKuraray.collection('email_credentials')
      .doc('gmail')
      .collection('users')
      .doc(this.state.email)
      .set({
        name :this.state.name ,
        email: this.state.email,
        password: this.state.password,
        location_id : this.state.locationId
      })
      .then(res => {
        window.location.reload()
        this.setState({
            open:false
        })
      })
    }


    render() {
        return (
            <>
                <Dialog
                    className="team-model"
                    open={this.state.open}
                    onClose={this.AddEmployeeClose}
                    aria-labelledby="responsive-dialog-title"
                >
                   <DialogTitle className="team-title">
                        Add Provider
                    </DialogTitle>

                    <div className="form-group row">
                        <div className="col-md-12" style={{ marginTop: '0px !important', marginBottom: '0px !important' }}>
                            <select className="service-type" name="type" id="type" onChange={this.handleRole}>
                            <option value="Select Provider">Select Provider</option>
                                <option value="gmail">Gmail</option>
                            </select>
                        </div>
                    </div>
                    {
                        this.state.type == 'gmail' ? 
                        <form>
                        <div className="form-group row">
                            <div className="col-md-12" style={{ marginTop: '0px !important', marginBottom: '0px !important' }}>
                                <input type="text" value={this.state.name} onChange={this.handleChange} name="name" className="form-control user-form-control" required id="name" placeholder="Name" />
                            </div>
                            
                        </div>
                        <div className="form-group row">
                           
                            <div className="col-md-12" style={{ marginTop: '0px !important', marginBottom: '0px !important' }}>
                                <input type="email" value={this.state.email} onChange={this.handleChange} name="email" className="form-control user-form-control" required id="email" placeholder="Email" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-12" style={{ marginTop: '0px !important', marginBottom: '0px !important' }}>
                                <input type="password" value={this.state.password} onChange={this.handleChange} name="password" className="form-control user-form-control" required id="password" placeholder="Password" />
                                <div id="password-exists">Must contain at least one number, one uppercase letter, one lowercase letter, one special character and minimum of 8 characters</div>
                            </div>
                        </div>

                        <DialogActions>
                            <button type="submit" style={{ margin: '0' }} className="lp-btns" onClick={this.handleClick}>Add</button>
                            <button type="submit" style={{ margin: '0' }} className="lp-btns" onClick={this.AddEmployeeClose}>Cancel</button>
                        </DialogActions>
                    </form>
                    :
                    <>
                    </>
                    }
                </Dialog>


                <h1>&nbsp; <span>
                    <a type="button" onClick={this.AddEmployeeOpen} className="btn-home" data-toggle="modal" data-target="#addEmployee" style={{ cursor: 'pointer' }}>
                        Add Service +
                    </a>
                </span></h1>
            </>
        )
    }
}

export default class EmailSettings extends Component {
    state = {
        users : [],
    }
    async componentDidMount(){
        await dbKuraray.collection('email_credentials')
      .doc('gmail')
      .collection('users')
    //   .where('location_id', '!=', '')
    //   .doc('agileads@headnurture.com')
      .where('location_id', '==', window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("location").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"))
      .get().then(async user => {
        if (user.docs.length == 0) {
            document.getElementById('noUsers').innerText = "There are no email is configured."
        }
        else {
        user.forEach((doc) => {
            this.setState({
                users : [...this.state.users, doc.data()]
            })
        })
    }
        // There are no email is configured.
      })
    }

    RemoveContact = (e) => {
        // console.log(this.state.location, e.target.id)
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this user?</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                dbKuraray.collection('email_credentials')
      .doc('gmail')
      .collection('users')
      .doc(e.target.id)
      .delete()
      .then(res => {
        onClose();
        window.location.reload()
      })
                            }}
                        >
                            Yes, Delete it!
                        </button>
                    </div>
                );
            }
        });

    }
    render() {
        return (
            <>
                <div className="wrapper-home">
                    <div className="report-group services report-lists">

                        <div className="container">
                        <div className="title" style={{ margin: 'auto' }}>
                            <div className="title-left" style={{ float: 'left' }}>
                                <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Services</h1>
                            </div>
                            <div className="title-right">
                                <Adduser />
                            </div>
                        </div>
                        <div className="wrapper">
                            <div>
                                <div className="cost-table-grp">
                                    <table className="cost-table users">
                                        <thead >
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Delete</th>
                                            </tr>
                                            {
                                                            this.state.users.length > 0 ?
                                                                this.state.users.map(function (name, index) {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{}</td>
                                                                            <td>{name.name}</td>
                                                                            <td>{name.email}</td>
                                                                            <td>
                                                                                <i className="fa fa-trash-o" id={name.email} onClick={this.RemoveContact.bind(this)} style={{ fontSize: '25px', marginTop: '2px' }}></i>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }, this)
                                                                :

                                                                <p className="noUsers" id="noUsers" style={{ margin: '10px 7px' }}> Loading...
                                                                     </p>
                                                                // <p className="noUsers" style={{ margin: '10px 7px' }}>{ setTimeout(() => {
                                                                //     "There are no email is configured."
                                                                // }, 3000) }</p>

                                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                    </div></div>
                </div>
            </>
        )
    }
}